var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FlashMessage',{staticClass:"glash"}),_c('div',{staticClass:"row"},[(_vm.showalbum == false)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"one2"},_vm._l((_vm.strategy[_vm.type]().albums),function(album){return _c('AlbumItem',{key:album.id,attrs:{"album":album,"editAlbum":() => _vm.editAlbum(album),"deleteAlbums":() => _vm.deleteAlbums(album.id),"canBeUpdate":!_vm.canBeUpdate(album),"showAlbumPictures":() => _vm.showAlbumPictures(album),"type":_vm.type}})}),1)]):_vm._e()]),(_vm.showalbum)?_c('div',{staticClass:"container-flex"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":_vm.hidealbum}},[_vm._v(" Back ")]),_c('span',{staticClass:"text-center ml-2 f-20"},[_vm._v(" "+_vm._s(this.album_name)+" ")]),_c('Images',{attrs:{"hasLoadPicture":_vm.hasLoadPicture,"album":_vm.album_id,"type":_vm.type,"albumName":_vm.album_name,"showAlbum":_vm.canViewAlbum,"canUpload":[
          'profile_picture',
          'Profile',
          'Cover',
          'cover_photo',
          'logo',
          'post'
        ].includes(_vm.album_name)
          ? false
          : true || _vm.canUpload,"images":_vm.strategy[_vm.type]().showAlbumImages},on:{"update:item":() => _vm.updateItem(),"reste":_vm.hidealbum}}),(!_vm.strategy[_vm.type]().showAlbumImages.length)?_c('div',{staticClass:"container-fluid"},[_c('p',{staticStyle:{"font-size":"3rem"}},[_vm._v(" "+_vm._s(_vm.$t("profileowner.No_items_found"))+" ")])]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }