var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"hide-footer":"","id":"product-details","size":"xl","title":"Product Details"},on:{"close":_vm.closeModal},model:{value:(_vm.canShowModal),callback:function ($$v) {_vm.canShowModal=$$v},expression:"canShowModal"}},[_c('LightBox',{ref:"lightboxh",attrs:{"media":[
      {
        type: 'image',
        thumb: _vm.product.picture,
        src: _vm.product.picture,
        id: 'fTs87IawpN4'
      }
    ],"show-caption":true,"show-light-box":false}}),_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('b-img',{staticClass:"w-100",attrs:{"src":_vm.product.picture},on:{"click":function($event){return _vm.$refs.lightboxh.showImage(0)}}})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"7"}},[_c('div',[_c('h4',{},[_vm._v(_vm._s(_vm.product.name))]),(_vm.product.in_stock)?_c('span',{staticClass:"text-success"},[_vm._v("In Stock")]):_c('span',{staticClass:"text-danger"},[_vm._v("Out of stock")]),_c('h4',{},[_vm._v(_vm._s(_vm.formatMoney(Number(_vm.product.price))))])]),_c('hr',{staticClass:"h-divider"}),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("general.Product_Desciption")))]),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.product.description)+" ")])]),_c('hr',{staticClass:"h-divider"}),_c('div',{staticClass:"buttons my-3 d-flex justify-content-between align-items-center"},[(_vm.product.user_package_name == 'basic')?_c('BtnCtaMessage',{attrs:{"element":_vm.product,"isProduct":true,"buyNow":true,"type":"business"}}):(_vm.product.user_package_name == 'premium')?_c('b-button',{staticClass:"font-weight-light shadow-sm",attrs:{"variant":"primary"},on:{"click":_vm.handleAddToCard}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.Buy_Now")))])]):_vm._e(),_c('b-button',{staticClass:"font-weight-light shadow-sm",attrs:{"variant":"light"},on:{"click":function($event){return _vm.goToWebsiteMarket(_vm.product)}}},[_vm._v(_vm._s(_vm.$t("general.Check_On_Website")))])],1),_c('hr',{staticClass:"h-divider"}),_c('div',{staticClass:"mt-3"},[_c('ProductComments',{attrs:{"idproduct":_vm.product.id,"product":_vm.product}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }