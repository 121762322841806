import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=27323f79&scoped=true"
import script from "./feedback.vue?vue&type=script&lang=js"
export * from "./feedback.vue?vue&type=script&lang=js"
import style0 from "./feedback.vue?vue&type=style&index=0&id=27323f79&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27323f79",
  null
  
)

export default component.exports