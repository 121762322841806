import { render, staticRenderFns } from "./contactandbasicinfo.vue?vue&type=template&id=424f8ae7&scoped=true"
import script from "./contactandbasicinfo.vue?vue&type=script&lang=js"
export * from "./contactandbasicinfo.vue?vue&type=script&lang=js"
import style0 from "./contactandbasicinfo.vue?vue&type=style&index=0&id=424f8ae7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424f8ae7",
  null
  
)

export default component.exports